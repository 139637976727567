{
  "layout": {
    "send": "Enviar",
    "cancel": "Cancelar",
    "error": "Erro",
    "open": "Abrir",
    "reset": "Reiniciar",
    "save_changes": "Guardar alterações",
    "visibility": "Visibilidade",
    "download": "Descarregar",
    "close": "Fechar",
    "delete": "Eliminar",
    "edit": "Editar",
    "view_all": "Ver todos",
    "export": "Exportar",
    "search": "Procurar",
    "yes": "Sim",
    "no": "Não",
    "options": "Opções",
    "export_csv": "Exportar CSV",
    "settings": "Configurações",
    "save": "Guardar",
    "shortcuts": "Atalhos",
    "next": "Próximo",
    "previous": "Anterior",
    "confirm": "Confirmar",
    "active": "Ativo",
    "view": "Ver",
    "load_more": "Carregar mais",
    "order_by": "Ordenar",
    "desc": "Descendente",
    "asc": "Ascendente"
  },
  "auth": {
    "logout": "Sair",
    "login": "Entrar",
    "register": "Registar",
    "no_account": "Ainda não tem uma conta?",
    "roles": {
      "admin": "Administrador",
      "tester": "Tester",
      "user": "Utilizador",
      "marketing": "Marketing",
      "analyses": "Análises"
    },
    "welcome": "Bem-vindo à {name}",
    "login_description": "Por favor entre na sua conta para aceder ao conteúdo",
    "email": "Email",
    "password": "Palavra-passe",
    "forgot_password_login": "Esqueceu-se da palavra-passe?",
    "lets_start": "Vamos começar",
    "already_have_account": "Já tem uma conta?",
    "agree": "Concordo com a",
    "privacy": "politica de privacidade",
    "register_description": "Obtenha as últimas informações e previsões de campo!",
    "verify_email": "Verificar email",
    "verify_email_description": "Link de verificação enviado para o seu email: {email}. Por favor, siga o link para continuar.",
    "resend": "Reenviar",
    "email_resent": "Email reenviado! Por favor, verifique a sua caixa de entrada para obter o link de verificação. Se ainda não encontrar o email, verifique a sua pasta de spam.",
    "reset_link": "Enviar link de reposição",
    "forgot_password": "Esqueceu-se da palavra-passe?",
    "forgot_password_description": "Insira o seu email e vamos-lhe enviar instruções para redefinir a sua palavra-passe.",
    "reset_link_sent": "Link de reposição enviado",
    "reset_password": "Recuperar palavra-passe",
    "new_password": "Nova palavra-passe",
    "confirm_password": "Confirmar palavra-passe",
    "reset": "Repor",
    "pasword_reset": "Palavra-passe reposta",
    "name": "Nome",
    "organization": "Organização",
    "wrong": "Email ou palavra-passe errados",
    "impersonating": "A representar {name}.",
    "leave": "Sair"
  },
  "navigation": {
    "dashboard": "Painel",
    "devices": {
      "title": "Dispositivos",
      "mine": "Meus",
      "public": "Públicos"
    },
    "assistant": "Assistente",
    "groups": "Grupos",
    "maps": "Mapas",
    "notebooks": "Cadernos de campo",
    "admin": "Admin",
    "logs": "Logs",
    "users": "Utilizadores",
    "blog": "Blog",
    "debug": "Debug",
    "contacts": "Contactos",
    "webcams": "Webcams",
    "analyses": "Análises",
    "satellite": "Satélite",
    "api_keys": "Chaves API",
    "observations": "Observações"
  },
  "dashboard": {
    "statistics": {
      "title": "Estatísticas",
      "devices": "Dispositivos",
      "groups": "Grupos",
      "parcels": "Parcelas",
      "maps": "Mapas",
      "notebooks": "Cadernos de campo"
    },
    "devices": {
      "title": "Dispositivos",
      "no_devices": "Sem dispositivos"
    },
    "maps": {
      "title": "Mapas",
      "no_maps": "Sem mapas"
    },
    "notebooks": {
      "title": "Cadernos de campo",
      "notes": "Notas: {number}",
      "no_notebooks": "Sem cadernos de campo"
    },
    "hello": "Olá {name}",
    "credits": "{number} créditos",
    "get_credits": "Obter créditos",
    "favorites": {
      "title": "Favoritos",
      "no_favorites": "Sem favoritos"
    }
  },
  "tables": {
    "showing": "A mostrar entradas {firstIndex} a {lastIndex} de {totalItems}",
    "show": "Mostrar:"
  },
  "devices": {
    "title": "Dispositivos",
    "search_device": "Procurar dispositivo",
    "name": "Nome",
    "model": "Modelo",
    "location": "Localização",
    "last_data": "Últimos Dados",
    "no_devices": "Nenhum dispositivo encontrado",
    "my_devices": "Meus dispositivos",
    "public_devices": "Dispositivos públicos",
    "public": "Público",
    "private": "Privado",
    "no_data": "Sem dados",
    "view_all": "Ver todos",
    "map": "Mapa",
    "elevation": "Altitude: {number}",
    "photos": "Fotografias",
    "no_photos": "Sem fotografias",
    "no_location": "Sem localização",
    "photo": "Fotografia",
    "add_photo": "Adicionar fotografia",
    "photo_added": "Fotografia adicionada",
    "edit_location": "Editar localização",
    "location_updated": "Localização atualizada",
    "wind": "Vento",
    "humidity": "Humidade",
    "uv": "Índice UV",
    "battery": "Bateria",
    "gust": "Rajadas: {number}km/h",
    "directions": {
      "n": "Norte",
      "ne": "Nordeste",
      "e": "Eeste",
      "se": "Sudeste",
      "s": "Sul",
      "sw": "Sudoeste",
      "w": "Oeste",
      "nw": "Noroeste"
    },
    "all_channels": "Todos os canais",
    "level": "Nível",
    "water_temperature": "Temperatura da água",
    "ph": "pH",
    "redox": "Redox",
    "oxygen_saturation": "Saturação de oxigénio",
    "oxygen_mgl": "Oxigénio mg/L",
    "oxygen_ppm": "Oxigénio PPM",
    "conductivity": "Condutividade",
    "salinity": "Salinidade",
    "tds_kci": "TDS-KCI",
    "csv_exported": "Ficheiro CSV exportado",
    "edit_device": "Editar dispositivo",
    "public_device": "Dispositívo público",
    "hibernated_device": "Dispositívo hibernado",
    "settings_updated": "Configurações atualizadas",
    "notes": "Notas",
    "views": "Visualizações",
    "maintenance": "Manutenção",
    "start_date": "Data de início",
    "end_date": "Data de fim",
    "view_warnings": "Ver alertas",
    "warnings": "Alertas",
    "warnings_types": {
      "disease": "Doença",
      "technical": "Técnico"
    },
    "warnings_descriptions": {
      "mildew": "Míldio",
      "no_data": "Sem dados"
    },
    "type": "Tipo",
    "description": "Descrição",
    "date": "Data",
    "no_warnings": "Sem alertas",
    "hourly_average": "Média horária",
    "air_quaility": "Qualidade do Ar",
    "air_quality_levels": {
      "1": "Muito bom",
      "2": "Bom",
      "3": "Médio",
      "4": "Fraco",
      "5": "Mau"
    },
    "water_quaility": "Qualidade da Água",
    "water_quality_levels": {
      "0": "Bom ou superior",
      "1": "Inferior a bom"
    },
    "view_weather_warnings": "Ver alertas meteorológicos",
    "online": "Online",
    "total": "Total"
  },
  "assistant": {
    "title": "Assistente",
    "description": "O assistente é uma ferramenta automatizada que usa o poder da inteligência artificial para fornecer conselhos e informações de especialistas sobre a gestão da vinha. O assistente foi projetado para auxiliar os proprietários de vinhas, enólogos e qualquer pessoa interessada na gestão da vinha. Pode responder a perguntas sobre uma ampla gama de tópicos, como gestão de solo, controle de pragas e doenças, tempo de colheita, poda, irrigação, agricultura orgânica, qualidade do solo, drenagem, mecanização, orçamento de vinha, agricultura de precisão, redução de impacto ambiental, mão-de-obra de vinha e sustentabilidade, entre outros.",
    "send": "Enviar"
  },
  "debug": {
    "title": "Debug",
    "email": "Enviar email de debug",
    "email_sent": "Email de debug enviado"
  },
  "groups": {
    "title": "Grupos",
    "description": "Os grupos permitem partilhar os seus dispositivos com outros utilizadores e ver dispositivos partilhados consigo.",
    "create_group": "Criar grupo",
    "members_number": "{number} membros",
    "member_number": "{number} membro",
    "admin": "Administrador",
    "member": "Membro",
    "devices_number": "Dispositivos: {number}",
    "group_created": "Groupo criado",
    "description_field": "Descrição",
    "name_field": "Nome",
    "devices": "Dispositivos",
    "add_devices": "Adicionar dispositivos",
    "device_deleted": "Dispositivo eliminado",
    "devices_added": "Dispositivos adicionados: {number}",
    "users": "Utilizadores",
    "user": {
      "user": "Utilizador",
      "email": "Email",
      "add_user": "Adicionar utilizador",
      "role": "Papel",
      "no_users": "Sem utilizadores",
      "search_user": "Procurar utilizador",
      "type": {
        "admin": "Administrador",
        "guest": "Convidado"
      },
      "user_added": "Utilizador adicionado",
      "user_deleted": "Utilizador eliminado",
      "email_not_found": "Email não encontrado",
      "already_in_group": "Utilizador já no grupo"
    },
    "no_groups": "Sem grupos",
    "name": "Nome",
    "date": "Data",
    "search_group": "Procurar grupo"
  },
  "settings": {
    "title": "Definições",
    "account": "Perfil",
    "upload_new_photo": "Carregar foto nova",
    "photo_details": "Permitido JPG ou PNG. Tamanho máxmo: 5M",
    "name": "Nome",
    "email": "E-mail",
    "organization": "Organização",
    "phone": "Número de telemóvel",
    "security": "Segurança",
    "photo_updated": "Fotografia atualizada",
    "account_updated": "Perfil atualizado",
    "change_password": "Alterar palavra-passe",
    "current_password": "Palavra-passe atual",
    "new_password": "Nova palavra-passe",
    "confirm_new_password": "Confirmar nova plavra-passe",
    "password_updated": "Palavra-passe atualizada"
  },
  "maps": {
    "title": "Mapas",
    "search_map": "Procurar mapa",   
    "name": "Nome",
    "area": "Área",
    "layers": "Camadas",
    "sampled_at": "Data de amostragem",
    "status": "Estado",
    "no_maps": "Sem mapas",
    "pending": "Pendente",
    "processed": "Processado",
    "public": "Público",
    "private": "Privado",
    "report": "Relatório",
    "download_files": "Descarregar ficheiros",
    "map": "Mapa",
    "extra": "Extra",
    "parcel": "Parcela",
    "add_map": "Adicionar mapa",
    "user": "Utilizador",
    "notes": "Notas",
    "extra_fields": "Campos extra",
    "add_extra_field": "Adicionar campo extra",
    "map_created": "Mapa adicionado",
    "add_layer": "Adicionar camada",
    "layer_created": "Camada adicionada. Pode demorar uns minutos a ser processada.",
    "edit_map": "Editar mapa",
    "add_report": "Adicionar relatório",
    "add_file": "Adicionar anexo",
    "no_layers": "Sem camadas",
    "visible": "Visível",
    "date": "Data",
    "layer_updated": "Camada atualizada",
    "layer_deleted": "Camada eliminada",
    "add_geojson": "Adicionar geojson",
    "geojson": "Geojson",
    "layer_name": "Nome da camada",
    "tiff": "Ficheiro TIFF",
    "geojson_added": "Geojson adicionado",
    "geojson_deleted": "Geojson eliminado",
    "delete_geojson": "Eliminar geojson",
    "delete_geojson_confirm": "Tem a certeza que pretende eliminar o geojson?",
    "report_added": "Relatório adicionado",
    "pdf": "PDF",
    "delete_report": "Eliminar relatório",
    "delete_report_confirm": "Tem a certeza que pretende eliminar o relatório?",
    "report_deleted": "Relatório eliminado",
    "file_deleted": "Anexo eliminado",
    "file": "Anexo",
    "delete_file": "Eliminar anexo",
    "delete_file_confirm": "Tem a certeza que pretende eliminar o anexo?",
    "file_added": "Anexo adicionado",
    "logs": "Logs",
    "attachments": "Anexos",
    "add_attachment": "Adicionar anexo",
    "value": "Valor"
  },
  "landing": {
    "go_dashboard": "Ir para o meu painel",
    "heading": "Um painel para gerir as tuas culturas",
    "subheading": "Utilize drones e sensores em quintas e florestas para monitorização da vegetação",
    "view_devices": "Ver dispositivos",
    "revolution": "Revolucione a sua Agricultura com a Kropie",
    "explore": "Explora as funcionalidades gratuitas.",
    "contact": "Contacto",
    "contact_us": "Contacte-nos",
    "contact_us_sub": "Alguma pergunta ou observação? Apenas escreva-nos uma mensagem.",
    "need_help": "Precisa de ajuda?",
    "faq": "FAQ",
    "about": "Sobre",
    "home": "Início",
    "login": "Entrar",
    "register": "Registar",
    "dashboard": "Painel",
    "stations": "Estações Online",
    "stations_description": "Vê os dados de estações meteorológicas públicas",
    "observations_description": "Vê as observações e informações meteorológicas públicas",
    "own_station": "Quer ter a sua própria estação meteorológica?",
    "own_station_description": "Entre em contacto connosco para saber mais.",
    "own_observation": "Quer partilhar as suas próprias observações e informações meteorológicas?",
    "own_observation_description": "Aceda à página de observações para começar as suas partilhas.",
    "email": "Email",
    "address": "Morada",
    "send_message": "Enviar uma mensagem",
    "name": "Nome",
    "write_message": "Escreva uma mensagem",
    "message": "Mensagem",
    "send": "Enviar",
    "devices": "Dispositivos",
    "sensors": "Sensores",
    "notebooks": "Cadernos de campo",
    "maps": "Mapas",
    "contact_success": "Mensagem enviada com sucesso",
    "webcams": "Webcams Meteorológicas",
    "webcams_description": "Vê cameras meteorológicas perto de ti",
    "open_webcam": "Abrir Webcam",
    "features": {
      "title": "Funcionalidades",
      "subtitle": "Tudo o que precisa para gerir a sua quinta",
      "weather_stations": "Estações Meteorológicas",
      "weather_stations_description": "Obtenha dados meteorológicos precisos e atualizados com as nossas estações meteorológicas de alta qualidade. As nossas estações recolhem dados em tempo real sobre vários parâmetros meteorológicos, como temperatura, humidade, precipitação e velocidade do vento, permitindo que tome decisões informadas sobre as suas práticas agrícolas. Com as nossas estações meteorológicas, pode estar sempre um passo à frente dos riscos relacionados com o clima e otimizar a sua produção agrícola.",
      "weather_stations_p1": "Dados em tempo real",
      "weather_stations_p2": "Fácil de instalar e usar",
      "weather_stations_p3": "Compatível com nossa plataforma",
      "weather_stations_p4": "Dados precisos",
      "ai_assistant": "Assistante AI",
      "ai_assistant_description": "Obtenha assistência inteligente e personalizada com o nosso assistente AI. O nosso assistente é treinado em vastas quantidades de dados agrícolas e pode ajudá-lo com uma variedade de tarefas, desde a configuração de cronogramas de irrigação até a identificação de possíveis pragas ou doenças, e até mesmo fornecer insights sobre o crescimento das culturas. Usando processamento de linguagem natural, nosso assistente pode entender as consultas do usuário e fornecer informações relevantes ou recomendações, ajudando-o a tomar decisões informadas e otimizar sua produção agrícola.",
      "ai_assistant_p1": "Informações e recomendações relevantes",
      "ai_assistant_p2": "Ajuda com tarefas simples",
      "ai_assistant_p3": "Auxilia na tomada de decisões",
      "notebook": "Caderno de Campo",
      "notebook_description": "Registe e acompanhe informações importantes sobre as suas culturas e atividades agrícolas com o nosso caderno de campo digital. Registe facilmente datas de plantio, estágios de crescimento das culturas e quaisquer problemas encontrados no campo. Pode também partilhar essas informações com outros membros da sua equipe para uma melhor colaboração e tomada de decisão.",
      "notebook_p1": "Registos fáceis e organizados",
      "notebook_p2": "Registo das datas de plantação e outras atividades",
      "notebook_p3": "Partilhar com membros da equipa",
      "satellite": "Imagens de Satélite",
      "satellite_description": "Mantenha-se atualizado com as últimas imagens de satélite da sua propriedade ou campo. A nossa plataforma fornece-lhe imagens de satélite de alta resolução atualizadas duas vezes por semana. Isso permite que você monitore as mudanças em suas culturas e no ambiente circundante, ajudando-o a tomar decisões informadas sobre a gestão das culturas. As nossas imagens também fornecem informações valiosas sobre padrões climáticos, níveis de umidade do solo e possíveis problemas, como surtos de pragas ou disseminação de doenças, permitindo que você tome medidas proativas para proteger suas culturas.",
      "satellite_p1": "Atualizado duas vezes por semana",
      "satellite_p2": "Vários índices agrícolas",
      "satellite_p3": "Ajuda a identificar potenciais problemas nas culturas",
      "drones": "Mapas de Drone",
      "drones_description": "Obtenha informações valiosas sobre a saúde das suas culturas com os nossos mapas de drone. A nossa plataforma fornece mapas detalhados das suas áreas de cultivo, incluindo vários índices de vegetação, como NDVI (Índice de Vegetação por Diferença Normalizada), NDRE (Índice de Vegetação por Diferença Normalizada na Banda Vermelha) e SAVI (Índice de Vegetação Ajustado ao Solo).",
      "drones_p1": "Imagens de alta resolução da sua parcela",
      "drones_p2": "NDVI, NDRE e outros índices",
      "drones_p3": "Análise detalhada da parcela",
      "drones_p4": "Acesso a arquivos e relatórios online",
      "monitoring": "Monitorização",
      "monitoring_description": "Obtenha monitorização em tempo real e avaliação de riscos para as suas culturas com os algoritmos avançados da nossa plataforma. Calculamos riscos importantes como o míldio e as geadas, assim como índices relevantes como os índices Winkler e Huglin e horas de frio, fornecendo-lhe informações valiosas para ajudá-lo a tomar decisões informadas para a sua exploração ou campo.",
      "monitoring_p1": "Cálculo de risco para míldio e geadas",
      "monitoring_p2": "Índices: Huglin, Winkler e Horas-frias",
      "monitoring_p3": "Alertas"
    },
    "analyses": "Análises",
    "analyses_heading": "Análises ao solo, água e folhas",
    "analyses_subheading": "A sua conta Kropie permite-lhe agora solicitar análises e consultar os resultados a qualquer momento.",
    "request_analyses": "Pedir Análise",
    "analyses_types": {
      "heading": "Tipos de Análises",
      "water": {
        "title": "Água",
        "parameters": "Bicarbonatos, boro, cálcio, cloretos, magnésio, nitratos, condutividade elétrica, pH, razão de adsorção de sódio ajustada e sódio."
      },
      "soil": {
        "title": "Solo",
        "parameters": "pH (H2O), Matéria Orgânica, Textura, Necessidade de Cal, Fósforo, Potássio, Magnésio."
      },
      "leaf": {
        "title": "Foliar",
        "parameters": "Azoto, fósforo, potássio cálcio, magnésio, enxofre, ferro, manganês, zinco, cobre e boro."
      }
    },
    "analyses_features": {
      "chip": "Vantagens",
      "subtitle": "Beneficie das facilidades do portal Kropie",
      "features": {
        "1": {
          "heading": "Acesso a qualquer hora, em qualquer lugar",
          "description": "Um ponto único de controlo e gestão, para mais facilmente comparar os resultados ao longo do tempo."
        },
        "2": {
          "heading": "Escolha o melhor prestador de serviço",
          "description": "Escolha a melhor proposta para o seu pedido. Caso já trabalhe com um prestador de serviços pode também solicitar que este se inscreva na plataforma e optar por continuar a trabalhar com ele."
        },
        "3": {
          "heading": "Receba alertas para os seus pedidos",
          "description": "Seja para cumprir obrigações legais ou para garantir um correto acompanhamento, pode calendarizar os seus pedidos em períodos definidos por si. Irá receber uma notificação para o alertar."
        }
      }
    },
    "analyses_faq": {
      "q1": "O que é a nova funcionalidade de Análise de Solo, Água e Folhas?",
      "a1": "Esta funcionalidade liga utilizadores a prestadores de serviço profissionais para uma análise abrangente do solo, água e folhas. Destina-se a ajudar agricultores, jardineiros e profissionais da agricultura a obter avaliações precisas das suas terras e da saúde das culturas, facilitando a tomada de decisões informadas para uma melhor gestão das culturas e sustentabilidade ambiental.",
      "q2": "Como funciona?",
      "a2": "Submissão de Pedido: Os utilizadores submetem um pedido de análise de solo, água ou folhas através da nossa plataforma. Correspondência: Com base nas necessidades específicas e na localização do utilizador, o nosso sistema associa-o a um prestador de serviços qualificado, especializado em análises agrícolas. Processo de Análise: O prestador de serviços realiza a análise e fornece um relatório detalhado com os achados e recomendações.",
      "q3": "Que informações preciso fornecer?",
      "a3": "Para assegurar uma correspondência precisa, os utilizadores devem fornecer: • O tipo de análise necessária (solo, água ou folha) • Preocupações ou objectivos específicos (ex.: deficiência de nutrientes, resistência a pragas) • Detalhes da localização para a correspondência da área de serviço • Preferência de tempo para a análise",
      "q4": "Quais os benefícios desta funcionalidade?",
      "a4": "Ao usar este recurso, pode: • Aceder a serviços de análise profissionais e especializados, adaptados às suas necessidades  • Receber recomendações de especialistas para melhorar a qualidade do solo, uso da água e saúde das plantas • Melhorar o rendimento e qualidade das culturas através de decisões informadas • Poupar tempo e esforço na procura de prestadores de serviços de reputação",
      "q5": "Quanto tempo demora a obter uma correspondência e receber um relatório?",
      "a5": "O processo de correspondência é geralmente rápido, sendo frequentemente concluído dentro de 24 horas. O tempo para receber o seu relatório de análise variará de acordo com a agenda do prestador de serviços e a complexidade da análise. Geralmente, os relatórios são fornecidos dentro de alguns dias a uma semana após a conclusão da análise pelo prestador de serviços, mas este valor é sempre da responsabilidade do prestador de serviços.",
      "q6": "Com que frequência devo solicitar uma análise?",
      "a6": "Recomendamos solicitar uma análise pelo menos uma vez por estação de crescimento ou sempre que observar mudanças significativas na saúde das culturas, na condição do solo ou na qualidade da água. Análises regulares podem ajudá-lo a monitorizar a saúde da sua terra ao longo do tempo e a fazer os ajustes necessários nas suas práticas agrícolas."
    },
    "satellites_drones": "Sat & Drones",
    "satellites_drones_heading": "Explore Imagens de Satélite e Drones com a Kropie",
    "satellites_drones_subheading": "Descubra novos insights com as imagens de satélite e drones da Kropie. Através da nossa plataforma digital, monitorize áreas agrícolas, florestaise ambientais em alta resolução, garantindo dados precisos e decisivos para uma gestão orientada por informações.",
    "request_satellites_drones": "Pedir Demonstração",
    "satellites_drones_functionalities": {
      "heading": "Principais funcionalidades",
      "real_time_monitoring": {
        "title": "Monitorização e Análise em Tempo Real",
        "parameters": "Com imagens de satélite atualizadas, acompanhe o estado das suas áreas de interesse e obtenha uma visão contínua do terreno."
      },
      "detailed_analyses_with_drones": {
        "title": "Análise Detalhada com Voos de Drones",
        "parameters": "Realize inspeções de alta precisão com drones, obtendo dados específicos de áreas críticas para uma leitura detalhada do terreno e das culturas."
      },
      "digital_platform": {
        "title": "Acesso Fácil via Plataforma Digital",
        "parameters": "Visualize e organize os seus dados de imagem na plataforma intuitiva da Kropie, facilitando uma gestão ágil e decisões informadas."
      }
    },
    "satellites_drones_applications": {
      "chip": "Aplicações",
      "subtitle": "Imagens Multispetrais avançadas e processamento de dados com IA em diferentes setores",
      "applications": {
        "1": {
          "heading": "Agricultura",
          "description": "Monitorize a saúde das culturas, detete sinais de stress e tome decisões informadas sobre irrigação e colheita."
        },
        "2": {
          "heading": "Florestas",
          "description": "Gerir a saúde das florestas, acompanhar padrões de crescimento e avaliar riscos como pragas e incêndios."
        },
        "3": {
          "heading": "Ambiente",
          "description": "Mapear e avaliar o uso do solo e impactos ambientais."
        }
      }
    },
    "satellites_drones_faq": {
      "q1": "Como posso aceder às imagens de satélite e drones na plataforma Kropie?",
      "a1": "Basta registar-se na nossa plataforma digital, onde poderá consultar imagens atualizadas e realizar análises detalhadas das suas áreas de interesse.",
      "q2": "Com que frequência são atualizadas as imagens de satélite?",
      "a2": "As atualizações de imagens de satélite dependem da cobertura e disponibilidade do serviço, mas são feitas regularmente para garantir uma monitorização contínua.",
      "q3": "Quais são os benefícios da análise por drones?",
      "a3": "Os drones oferecem uma leitura detalhada e personalizada, ideal para captar dados específicos e de alta precisão em áreas críticas que precisam de monitorização intensiva.",
      "q4": "A Kropie oferece suporte para interpretação dos dados?",
      "a4": "Sim, a nossa equipa pode ajudar na interpretação dos dados e apoiar no uso das ferramentas analíticas disponíveis na plataforma.",
      "q5": "Posso combinar dados de satélite com dados de drones?",
      "a5": "Sim, a nossa plataforma permite a integração de ambos os tipos de dados, proporcionando uma visão mais completa e detalhada da sua área.",
      "q6": "Que tipo de análises posso realizar com as imagens disponíveis?",
      "a6": "A plataforma Kropie permite realizar análises como monitorização de saúde de culturas, deteção de pragas, medição de humidade, e outros parâmetros essenciais para a gestão agrícola e florestal."
    }
  },
  "faq": {
    "title": "FAQ",
    "title_long": "Perguntas Frequentes",
    "description": "Deixe-nos ajudar a responder às perguntas mais comuns.",
    "q1": "Posso aceder aos dados recolhidos pela estação meteorológica em tempo real?",
    "a1": "Sim, pode aceder aos dados recolhidos pela estação meteorológica em tempo real através da nossa plataforma. Fornecemos atualizações em tempo real dos parâmetros meteorológicos medidos pela estação.",
    "q2": "Como é que a vossa plataforma calcula parâmetros importantes para o crescimento das culturas?",
    "a2": "A nossa plataforma utiliza vários dados de entrada, como dados meteorológicos, dados do solo e dados das culturas para calcular parâmetros importantes para o crescimento das culturas, como evapotranspiração, requisitos de irrigação e stress hídrico das culturas. Usamos algoritmos avançados e técnicas de aprendizagem automática para fornecer cálculos precisos.",
    "q3": "Que tipo de mapas de drones e índices posso esperar ver na Kropie?",
    "a3": "A nossa plataforma fornece mapas de drones com vários índices, como NDVI (Índice de Vegetação por Diferença Normalizada), NDRE (Índice de Vegetação por Diferença Normalizada na Borda Vermelha) e SAVI (Índice de Vegetação Ajustado ao Solo). Esses índices podem ajudá-lo a avaliar a saúde das plantas e identificar potenciais problemas com as suas culturas.",
    "q4": "Como funciona o Assistente de IA?",
    "a4": "O nosso assistente de IA usa processamento de linguagem natural para entender as consultas dos utilizadores e fornecer informações ou recomendações relevantes. Pode ajudá-lo em tarefas como configurar cronogramas de irrigação, identificar potenciais pragas ou doenças e ajudá-lo em tarefas simples.",
    "q5": "O que é um caderno de campo e como me ajuda no meu trabalho agrícola?",
    "a5": "O nosso caderno de campo é um caderno digital que lhe permite registar informações importantes sobre as suas culturas e atividades agrícolas. Pode ajudá-lo a controlar as datas de plantio, os estágios de crescimento das culturas e quaisquer problemas que encontre no campo. Também pode partilhar esta informação com outros membros da sua equipa.",
    "q6": "Com que frequência é atualizada a imagética de satélite na Kropie?",
    "a6": "Atualizamos a nossa imagética de satélite duas vezes por semana, fornecendo-lhe as informações mais recentes sobre a sua quinta ou campo.",
    "q7": "Os meus dados estão seguros na vossa plataforma?",
    "a7": "Sim, levamos a segurança dos dados muito a sério e usamos medidas de criptografia e segurança padrão da indústria para proteger os seus dados. Também temos políticas estritas de privacidade de dados para garantir que os seus dados sejam usados apenas para os fins pretendidos."
  },
  "notebooks": {
    "title": "Cadernos de campo",
    "description": "Bem-vindo à sua página de Cadernos de campo! Aqui pode visualizar e gerir todos os seus cadernos relacionados com a agricultura. Seja um agricultor, jardineiro ou apenas alguém que tem um dedo verde, esta página foi desenhada para o ajudar a manter o registo das suas ideias, observações e planos para as suas culturas.",
    "create": "Criar caderno de campo",
    "notes_number": "Notas: {number}",
    "notebook_created": "Caderno de campo criado",
    "add_notebook": "Criar caderno de campo",
    "edit_note": "Editar nota",
    "name": "Nome",
    "add_note": "Adicionar nota",
    "add": "Adicionar",
    "note_created": "Nota criada",
    "note_updated": "Nota atualizada",
    "description_field": "Descrição",
    "note_type": "Tipo de nota",
    "types": {
      "observation": "Observação",
      "weather": "Meteorológia",
      "disease": "Doença",
      "pruning": "Poda",
      "fertilization": "Fertilização",
      "pesticide": "Pesticida",
      "harvest": "Colheita",
      "labor": "Trabalho",
      "expense": "Despesa",
      "sale": "Venda",
      "other": "Outro"
    },
    "notes": "Notas",
    "search_note": "Procurar nota",
    "select_type": "Selecionar um tipo de nota",
    "date": "Data",
    "no_notes": "Sem notas",
    "location": "Localização",
    "last_note": "Última nota: {date}",
    "delete_notebook": "Eliminar caderno de campo",
    "deleted_notebook": "Caderno de campo eliminado",
    "delete_notebook_confirm": "Tem a certeza que pretende eliminar este caderno de campo?",
    "delete_note": "Eliminar nota",
    "deleted_note": "Nota eliminada",
    "delete_note_confirm": "Tem a certeza que pretende eliminar esta nota?",
    "exporting_notebook": "A exportar o caderno de campo",
    "last_entry": "Última nota",
    "user": "Utilizador",
    "entries": "Entradas",
    "no_notebooks": "Sem cadernos de campo",
    "name_already_used": "Nome do caderno de campo já utilizado"
  },
  "days": {
    "short": {
      "0": "Dom",
      "1": "Seg",
      "2": "Ter",
      "3": "Qua",
      "4": "Qui",
      "5": "Sex",
      "6": "Sáb"
    }
  },
  "logs": {
    "title": "Logs",
    "module": "Módulo",
    "user": "Utilizador",
    "description": "Descrição",
    "date": "Data",
    "no_logs": "Sem logs",
    "search_log": "Procurar log"
  },
  "users": {
    "title": "Utilizadores",
    "search_users": "Procurar utilizadores",
    "name": "Nome",
    "email": "Email",
    "organization": "Organização",
    "phone": "Telemóvel",
    "credits": "Créditos",
    "type": "Tipo",
    "no_users": "Sem utilizadores",
    "roles": {
      "admin": "Admin",
      "tester": "Tester",
      "user": "Utilizador",
      "marketing": "Marketing",
      "analyses": "Analyses"
    },
    "details": "Detalhes",
    "add_credits": "Adicionar créditos",
    "credits_added": "Créditos adicionados",
    "last_seen": "Online",
    "change_avatar": "Alterar avatar",
    "photo": "Fotografia",
    "avatar_updated": "Avatar atualizado",
    "account_type": "Tipo de conta",
    "order_by_date": "Data",
    "order_by_name": "Nome",
    "order_by_email": "Email",
    "order_by_last_seen": "Online"
  },
  "blog": {
    "blog": "Blog",
    "search_post": "Procurar publicação",
    "title": "Título",
    "date": "Data",
    "no_posts": "Sem publicações",
    "slug": "Slug",
    "post_added": "Publicação adicionada",
    "add_post": "Adicionar publicação",
    "articles": "Artigos"
  },
  "contacts": {
    "title": "Contactos",
    "search_contact": "Procurar contacto",
    "no_contacts": "Sem contactos",
    "name": "Nome",
    "email": "Email",
    "message": "Mensagem",
    "answered": "Respondido",
    "date": "Data"
  },
  "forbidden": {
    "title": "Não está autorizado!",
    "subtitle": "Não tem permissão para aceder a esta página. Voltar ao início!",
    "button": "Voltar ao início"
  },
  "not_found": {
    "title": "Página não encontrada",
    "subtitle": "Não conseguimos encontrar a página que está a procurar.",
    "button": "Voltar ao início"
  },
  "webcams": {
    "search_webcam": "Procurar webcam",
    "name": "Nome",
    "location": "Localização",
    "views": "Visualizações",
    "updated": "Atualizada",
    "no_webcams": "Sem webcams"
  },
  "analyses": {
    "title": "Análises",
    "companies": "Prestador de análises",
    "no_companies": "Sem prestadores",
    "search_company": "Procurar prestador",
    "provider_name": "Prestador",
    "provider_users": "Utilizadores",
    "provider_analyses": "Análises",
    "users": "Utilizadores",
    "user": "Utilizador",
    "email": "Email",
    "add_user": "Adicionar utilizador",
    "no_users": "Sem utilizadores",
    "search_user": "Procurar utilizador",
    "user_added": "Utilizador adicionado",
    "user_deleted": "Utilizador eliminado",
    "email_not_found": "Email não encontrado",
    "already_added": "Utilizador já adicionado",
    "requests": "Pedidos",
    "search_request": "Procurar pedido",
    "accepted": "Aceite",
    "collect": "Recolha",
    "paid": "Pago",
    "type": "Tipo",
    "date": "Data",
    "water": "Água",
    "soil": "Solo",
    "leaf": "Foliar",
    "no_requests": "Sem pedidos",
    "request_analysis": "Pedir análise",
    "analysis_type": "Tipo de análise",
    "select_analysis_type": "Selecione o tipo de análise",
    "location": "Localização",
    "select_location": "Selecione a localização",
    "info": "Informação adicional",
    "add_info": "Adicionar informação adicional",
    "review": "Rever",
    "review_request": "Rever pedido",
    "water_description": "Avalia a qualidade da água quanto a contaminantes, pH e teor mineral.",
    "soil_description": "Avalia a saúde do solo, nutrientes e possíveis poluentes.",
    "leaf_description": "Examina os nutrientes das folhas das plantas para um crescimento ótimo.",
    "request_collect": "Solicitar que a amostra seja recolhida?",
    "collect_description": "Se não solicitar a recolha da sua amostra, receberá instruções para enviá-la pelo correio.",
    "observations": "Observações",
    "observations_description": "Por favor adicione informações que considere relevantes.",
    "review_note": "Receberá um email quando o seu pedido for aprovado. Pode-nos contactar sobre o pedo na página do pedido.",
    "number": "Número",
    "request_submitted": "Pedido de análise submetido com sucesso",
    "request": "Pedido",
    "finished": "Concluído",
    "unpaid": "Não pago",
    "collect_requested": "Recolha pedida",
    "request_details": "Detalhes do Pedido",
    "total": "Total",
    "comments": "Comentários",
    "send": "Enviar",
    "comment_placeholder": "Escreva o seu comentário...",
    "comment_sent": "Comentário enviado",
    "activity": "Atividade",
    "request_placed": "Pedido enviado (#{number})",
    "request_placed_description": "O seu pedido de análise foi pedida com sucesso",
    "result_published": "Resultado publicado",
    "result_published_description": "O resultado da análise foi publicado e está agora dispoível",
    "invoice_uploaded": "Nova fatura adicionada",
    "invoice_uploaded_description": "Tem uma nova fatura disponível",
    "receipt_uploaded": "Novo recibo adicionado",
    "receipt_uploaded_description": "Tem um novo recibo disponível",
    "request_finished": "Pedido concluido",
    "request_finished_description": "Este pedido foi marcado como concluido",
    "download_receipt": "Descarregar Recibo",
    "download_invoice": "Descarregar Fatura",
    "download_result": "Descarregar Resultado",
    "request_accepted": "Pedido aceite",
    "request_accepted_description": "O seu pedido foi aceite",
    "request_refused": "Pedido recusado",
    "request_refused_description": "O seu pedido foi recusado",
    "payment_done": "Pagamento efetuado",
    "payment_done_description": "O seu pedido foi marcada como pago",
    "status": "Estado",
    "pending": "Pendente",
    "progress": "Em progresso",
    "upload_invoice": "Carregar fatura",
    "invoice_added": "Fatura adicionada",
    "pdf": "PDF",
    "than_last_month": "do que no mês passado",
    "pending_alert": "Este pedido encontra-se pendente. Por favor, aceite-a ou recuse-a.",
    "accept": "Aceitar",
    "refuse": "Recusar",
    "refuse_request": "Recusar pedido",
    "refuse_request_confirm": "Tem a certeza que pretende recusar este pedido? Se sim, por favor, escreva um comentário no pedido a explicar a razão.",
    "refused_alert": "Este pedido foi recusado a {date}.",
    "refused": "Recusado",
    "result": "Resultado",
    "result_available": "Resultado Disponível",
    "result_available_description": "O resultado para este pedido está disponívvel. Pode-o ver e descarregar no botão em baixo.",
    "pending_alert_user": "Este pedido encontra-se pendente. Por favor, aguarde até que seja aceite ou recusado.",
    "set_price": "Definir preço",
    "set_price_update_warning": "O utilizador será notificado quando o preço for alterado.",
    "price": "Preço",
    "price_set_success": "Preço atualizado com sucesso",
    "replace_invoice": "Substituir fatura",
    "upload_receipt": "Carregar recibo",
    "replace_receipt": "Substituir recibo",
    "upload_result": "Carregar resultado",
    "replace_result": "Substituir resultado",
    "receipt_added": "Recibo adicionado",
    "result_added": "Resultado adicionado",
    "clients": "Clientes",
    "search_client": "Procurar cliente",
    "no_clients": "Sem clientes",
    "finish_request": "Concluir pedido",
    "finish_request_confirm": "Tem a certeza que pretende concluir este pedido? Após concluído não é possível fazer alterações.",
    "finished_alert": "Este pedido encontra-se concluido.",
    "details": "Detalhes",
    "spent": "Gasto",
    "analyses": "Análises",
    "new_request": "Novo pedido",
    "getting_location": "A obter localização...",
    "no_address": "Nenhuma morada encontrada",
    "search_address": "Procurar morada",
    "address": "Morada",
    "provider": "Fornecedor",
    "set_as_paid": "Marcar como pago",
    "set_as_paid_confirm": "Deseja marcar este pedido como pago?",
    "request_set_as_paid": "Pedido marcado como pago",
    "request_new_analysis": "Requerer análise",
    "request_new_analysis_description": "Clique no botão em baixo para iniciar um novo pedido de análise. Por favor, preencha o formulário adequadamente.",
    "start": "Iniciar",
    "invoiced": "Faturado",
    "open_google_maps": "Abrir no Google Maps",
    "add_provider": "Adicionar prestador",
    "provider_created": "Prestador adicionado",
    "requests_not_attributed": "Pedidos não atribuidos",
    "to_be_attributed": "Para atribuição",
    "select_provider": "Selecionar prestador",
    "provider_attributed": "Prestador atribuido"
  },
  "satellite": {
    "title": "Imagens de Satélite",
    "parcel": "Parcela",
    "parcels": "Parcelas",
    "search_parcel": "Procurar parcela",
    "name": "Nome",
    "area": "Área",
    "user": "Utilizador",
    "validated": "Validada",
    "last_data": "Últimos Dados",
    "active": "Ativo",
    "no_parcels": "Sem parcelas",
    "no_data": "Sem dados",
    "add_parcel": "Adicionar parcela",
    "geojson": "Geojson",
    "number_days": "Número de dias de histórico",
    "index": "Índice",
    "select_index": "Selecionar índice",
    "no_entries": "Ainda não existem imagens de satélite para o índice selecionado.",
    "min": "Mínimo",
    "max": "Máximo",
    "average": "Média",
    "parcel_created_success": "Parcela adicionada com sucesso",
    "description": "Explore imagens de satélite e índices como o NDVI e NDWI.",
    "indexes": {
      "ndvi": "NDVI",
      "ndvi_long": "Normalized Difference Vegetation Index",
      "ndvi_description": "NDVI é utilizado para quantificar a densidade da vegetação (biomassa verde viva). O NDVI pode variar entre -1 e 1. Este indice varia em função do crescimento e estado da vegetação. Uma área com vegetação densa e saudável terá um valor NDVI elevado. Valores elevados de NDVI (entre 0,6 e 1) indicam vegetação saudável, enquanto valores baixos de NDVI (entre -0,1 e 0,1) indicam solo nu ou água. Valores entre 0,1 e 0,6 indicam uma cobertura vegetal moderada.",
      "evi": "EVI",
      "evi_long": "Enhanced Vegetation Index",
      "evi_description": "EVI é um índice de vegetação concebido para melhorar o sinal de vegetação com maior sensibilidade em regiões com elevada biomassa e uma alta densidade de vegetação. O intervalo de valores para o EVI é de -1 a +1 e, para vegetação saudável, varia entre 0,2 e 0,8. O EVI é sensível às variações estruturais das plantas, incluindo o índice de área foliar, o tipo de copado, a fisionomia da planta e arquitetura das copas.",
      "evi2": "EVI2",
      "evi2_long": "Enhanced Vegetation Index 2",
      "evi2_description": "O EVI2 é uma extensão do EVI que é utilizada para reduzir o impacto do ruído que possa existir no EVI.",
      "ndwi": "NDWI",
      "ndwi_long": "Normalized Difference Water Index",
      "ndwi_description": "O NDWI pode ser usado para monitorizar as mudanças no conteúdo de água das folhas. Os valores de NDWI podem variar entre -1 e 1. Valores elevados de NDWI correspondem a um elevado teor de água na planta, enquanto valores baixos de NDWI correspondem a um baixo teor de vegetação e a um coberto reduzido de vegetação.",
      "nri": "NRI",
      "nri_long": "Nitrogen Reflectance Index",
      "nri_description": "O NRI é um índice utilizado para estimar o teor de azoto (N) nas folhas. Valores mais elevados podem significar níveis mais elevados de azoto, enquanto valores mais baixos podem significar o oposto.",
      "dswi": "DSWI",
      "dswi_long": "Disease-Water Stress Index",
      "dswi_description": "O DWSI pode ser utilizado para detetar culturas com stress hídrico, sendo também sensível a alterações nos pigmentos das folhas, na estrutura interna das folhas e no teor de humidade.",
      "truecolor": "TC",
      "truecolor_long": "True Color",
      "truecolor_description": "True Color são imagens que representam a luz visível ao olho humano. Podem ser utilizadas para avaliar visualmente o estado das culturas ou de parcelas agrícolas.",
      "falsecolor": "FC",
      "falsecolor_long": "False Color",
      "falsecolor_description": "False Color são imagens criadas através da utilização de comprimentos de onda fora do espetro visível para realçar características específicas. Podem ser usadas para aumentar o contraste entre diferentes tipos de vegetação ou entre vegetação e áreas sem vegetação, facilitando a identificação de padrões ou anomalias."
    },
    "disclaimer": "Apesar dos índices fornecidos terem sido objeto de uma ampla validação científica, os resultados fornecidos pelos mesmos devem ser vistos como um auxílio à tomada de decisão, sendo que os seus resultados devem ser confrontados com inspeções no terreno. Além disso, uma vez que os índices são cálculos com base em imagens adquiridas por satélite, podem existir flutuações nos valores ou falta de dados devido a condições meteorológicas não favoráveis no momento da aquisição dos dados como a presença de nuvens ou sombras causadas por estas.",
    "more_info": "Mais informação",
    "soil_data": "Dados do Solo",
    "soil_moisture": "Humidade do Solo",
    "view_historic": "Ver histórico",
    "surface_temperature": "Temperatura à superfície",
    "surface_temperature_10cm": "Temperatura a 10cm"
  },
  "api_keys": {
    "title": "Chaves API",
    "add_key": "Adicionar chave",
    "device": "Dispositivo",
    "key": "Chave",
    "read": "Leitura",
    "write": "Escrita",
    "updated": "Atualizado",
    "requests": "Pedidos",
    "no_keys": "Sem chaves",
    "search_key": "Procurar chave",
    "key_created": "Chave adicionada",
    "length": "Tamanho",
    "key_deleted": "Chave eliminada"
  },
  "observations": {
    "title": "Observações",
    "description": "Partilhe as suas observações e informações meteorológicas diárias. Desde mudanças rápidas no tempo, ventos fortes ou condições incomuns, todas as suas notas ajudam a acompanhar o clima local",
    "search_observation": "Procurar observação",
    "observation_title": "Título",
    "user": "Utilizador",
    "date": "Data",
    "views": "Visualizações",
    "no_observations": "Sem observações",
    "type": "Tipo",
    "types": {
      "wind": "Vento",
      "hail": "Granizo",
      "clouds": "Nuvens",
      "flood": "Inundação",
      "downpour": "Tromba de água",
      "rain": "Chuva",
      "snow": "Neve",
      "fog": "Nevoeiro",
      "thunderstorm": "Trovoada",
      "ice": "Gelo",
      "other": "Outro",
      "wildfire": "Incêndio"
    },
    "images": "Fotografias",
    "videos": "Videos",
    "submit_observation": "Submeter observação",
    "media": "Media",
    "my_observations": "As minhas observações",
    "title_field": "Título",
    "description_field": "Descrição",
    "type_field": "Tipo",
    "select_type": "Selecionar tipo",
    "observation_sent": "Observação submetida",
    "type_country": "País",
    "select_country": "Selecionar país",
    "type_city": "Cidade",
    "select_city": "Selecionar cidade",
    "video": "Vídeo",
    "photos": "Fotografias",
    "max_photos_alert": "Apenas pode selecionar :number fotografias.",
    "observation_date": "Data da observação",
    "image_or_video_required": "Deve selecionar pelo menos uma imagem ou um vídeo.",
    "order_by_date": "Data",
    "order_by_title": "Título",
    "order_by_views": "Visualizações",
    "search_city": "Procurar cidade",
    "view_map": "Ver mapa"
  },
  "terms_and_policy": {
    "title": "Termos e Condições & Políticas de Privacidade",
    "terms": {
      "title": "Termos e Condições",
      "last_update": "Última atualização: 21 de junho 2024",
      "p1": "Esta é uma plataforma gerida pela Kropie e está sujeita aos presentes Termos e Condições. Estes servem para sua segurança e para mostrar nossa transparência em relação aos serviços que lhe prestamos e, como utilizador, deve lê-los cuidadosamente antes de prosseguir a navegação nesta plataforma. Caso tenha alguma dúvida, ou necessite de informação adicional sobre os presentes Termos e Condições, poderá contactar a Kropie através do contacto providenciado na secção de disposições finais. Ao aceder e utilizar a presente plataforma está a concordar com os Termos e Condições aqui expressos. Caso não concorde com os mesmo não deverá utilizar a plataforma.",
      "sec1": {
        "title": "1. Aceitação dos Termos e Condições",
        "p1": "A Kropie reserva-se no direito de alterar os presentes Termos e Condições sempre que necessário. Sempre que forem introduzidas alterações aos presentes Termos e Condições será apresentado um aviso, no momento de acesso à plataforma, ou ser-lhe-á enviado um email, juntamente com um pedido de aceitação dos novos Termos e Condições.",
        "p2": "Sem essa aceitação não poderá continuar a aceder e a utilizar o site. As alterações feitas aos Termos e Condições não são aplicáveis retroativamente e entram em vigor no dia da sua publicação.",
        "p3": "A data da atualização mais recente será claramente indicada na parte superior desta página.",
        "p4": "Enquanto utilizador, pode ler os presentes Termos e Condições em qualquer momento, usando o link constante na parte inferior da página de entrada da plataforma.",
        "p5": "Os Termos e Condições aqui definidos aplicam-se apenas ao uso desta plataforma e, portanto, não são aplicáveis a quaisquer sites externos cujos links sejam fornecidos. Tais links são fornecidos com o único propósito de facilitar o acesso a informações adicionais e para conveniência do utilizador.",
        "p6": "A Kropie não pode ser responsabilizada por quaisquer erros, informações desatualizadas ou danos que possam resultar do acesso e/ou utilização de sites ou aplicações externas, uma vez que não tem qualquer controlo sobre os mesmos. Os utilizadores são os únicos responsáveis pelo acesso e uso de quaisquer sites administrados por terceiros.",
        "p7": "A Kropie reserva-se ainda no direito de recusar ou de remover e interditar o acesso a esta plataforma, em qualquer momento e sem aviso prévio, aos utilizadores que não cumpram os Termos e Condições expressos nesta página."
      },
      "sec2": {
        "title": "2. Objeto da plataforma",
        "p1": "Esta plataforma tem como objetivo reunir informações provenientes de estações meteorológicas, análises de solo e do ar, satélites e drones numa única base de dados para a gestão urbana, agrícola, vitivinícola e florestal. A plataforma permite monitorizar em tempo real, diferentes métricas, otimizando recursos e levando a uma melhor e mais rápida tomada de decisão."
      },
      "sec3": {
        "title": "3. A sua conduta ao utilizar esta plataforma",
        "p1": "O utilizador deve usar esta plataforma com responsabilidade, não deve comprometer a continuidade, integridade e qualidade dos seus conteúdos e funcionalidades, nem interferir com os direitos e/ou a capacidade de outros utilizadores a usarem.",
        "p2": "O utilizador não pode usar os serviços da plataforma, em alguma circunstância, com o objetivo de monitorizar a disponibilidade da mesma, o seu desempenho, funcionalidades ou qualquer outra métrica ou propósito competitivo.",
        "p3": "É expressamente proibido utilizar esta plataforma com o objetivo de disponibilizar ou partilhar qualquer conteúdo ilegal.",
        "p4": "Quaisquer tentativas de alteração de informação, de carregamento de informação, ou qualquer outra ação que possa causar dano e/ou colocar em risco a integridade do sistema, é expressamente proibida, podendo ser punida de acordo com a legislação em vigor.",
        "p5": "É expressamente proibido utilizar a plataforma para fornecer ou transferir qualquer tipo de material, arquivos ou programas que possam interromper, destruir ou limitar a funcionalidade da plataforma.",
        "p6": "Não é permitida a publicação de qualquer informação ou conteúdo pertencentes a terceiros, ou sobre os quais o utilizador não detenha os direitos de uso, como, por exemplo, conteúdo protegido por direito de autor de terceiros ou conteúdo que contenha dados pessoais de terceiros."
      },
      "sec4": {
        "title": "4. Partilha de dados",
        "p1": "Com exceção das entidades que prestam serviço no que respeita à operação desta plataforma, a Kropie não partilhará com terceiros os dados pessoais que faculte, exceto nos termos previstos no <a href=\"https://eur-lex.europa.eu/legal-content/PT/TXT/PDF/?uri=CELEX:32016R0679&from=PT\" target=\"_blank\">Regulamento Geral de Proteção de Dados (RGPD)</a>."
      },
      "sec5": {
        "title": "5. Links externos",
        "p1": "Nesta plataforma poderão ser facultados links para sites de terceiros, pelos quais a Kropie não é responsável. A Kropie não se responsabiliza pelo conteúdo e funcionalidades dos referidos sites, estando a sua utilização sujeita aos seus próprios termos e condições de utilização e declarações de privacidade, pelo que recomendamos a sua leitura."
      },
      "sec6": {
        "title": "6. Propriedade intelectual",
        "p1": "Esta plataforma e respetivos conteúdos encontram-se protegidos por Direitos de Autor, Direitos Conexos e Direitos de Propriedade Industrial, ao abrigo da legislação portuguesa e da União Europeia, e de outras convenções internacionais aplicáveis, sendo o utilizador obrigado a respeitá-los.",
        "p2": "Esta plataforma, bem como todos os elementos nele presentes, nomeadamente textos, imagens, vídeos, logótipos, são propriedade da Kropie, ou os respetivos direitos terão sido previamente licenciados ou cedidos à Kropie.",
        "p3": "O utilizador não tem o direito de usar os conteúdos do presente site para quaisquer fins que não os previstos nos presentes Termos e Condições.",
        "p4": "O utilizador pode, contudo, exportar alguns dos conteúdos presentes nesta plataforma, sempre que o mesmo lhe for permitido através da funcionalidade para o efeito e desde que não os modifique, que sejam destinados a uso pessoal, não comercial, que contenham uma referência à sua fonte, e desde que qualquer outra indicação de propriedade ou direitos de propriedade intelectual não sejam removidos.",
        "p5": "Os direitos de propriedade intelectual não se aplicam aos discursos, declarações ou comunicados produzidos pela Kropie ou pelos seus parceiros, embora os seus autores, data e circunstâncias devam ser referidos para boa informação.",
        "p6": "Kropie é uma marca registada junto do Instituto Nacional de Propriedade Industrial (INPI) desde fevereiro de 2022."
      },
      "sec7": {
        "title": "7. Isenção de responsabilidade",
        "p1": "A Kropie dedicará todos os esforços para disponibilizar nesta plataforma informação atualizada, abrangente e precisa.",
        "p2": "No entanto, e nos termos da legislação em vigor, a Kropie não assume qualquer responsabilidade por consequências direta ou indiretamente relacionadas com qualquer ação ou omissão por parte do utilizador com base nas informações e conteúdos disponibilizados nesta plataforma."
      },
      "sec8": {
        "title": "8. Exclusão de garantias",
        "p1": "A Kropie não pode garantir o acesso ininterrupto, seguro ou isento de erros à sua plataforma, ou que eventuais erros sejam atempadamente corrigidos. Enquanto utilizador, aceita que qualquer material ou informação obtidos serão usados por sua própria conta e risco, e que é responsável por qualquer dano que possa ser causado ao sistema operativo ou software do dispositivo utilizado para aceder à presente plataforma ou por qualquer perda de dados que possa resultar do download de conteúdos ou informações constantes na mesma.",
        "p2": "O utilizador é o único responsável por garantir a proteção dos seus dispositivos contra ameaças resultantes de quaisquer vírus e outras falhas de segurança. Adicionalmente, o utilizador deve estar ciente de que, caso não adote as medidas de segurança adequadas, existe um risco acrescido de que os seus dados, palavras-passe e dispositivos sejam acedidos por terceiros.",
        "p3": "Nesta plataforma poderão ser facultados links para sites de terceiros, sobre os quais a Kropie não tem qualquer controlo. A Kropie não se responsabiliza pelo conteúdo e funcionalidades dos referidos sites, estando a sua utilização sujeita aos seus próprios termos e condições de utilização e declarações de privacidade, pelo que recomendamos a sua leitura.",
        "p4": "Todos os conteúdos fornecidos por entidades externas, devidamente identificadas são da sua exclusiva responsabilidade. A Kropie procura, contudo, que esses conteúdos detenham elevados níveis de fiabilidade e de credibilidade não podendo, no entanto, dar qualquer garantia quanto ao seu rigor e à sua atualidade.",
        "p5": "É objetivo desta plataforma minimizar os inconvenientes causados por erros técnicos. Todavia, determinados dados ou informações nela contidas podem ter sido criados, obtidos e/ou estruturados em/de ficheiros ou formatos não isentos de erros.",
        "p6": "A Kropie, não pode garantir a não interrupção ou a não perturbação do serviço devido a problemas técnicos. Em consequência, a Kropie não assume qualquer responsabilidade por problemas, que surjam para os utilizadores ou entidades terceiras em virtude da consulta desta plataforma ou de quaisquer sites externos a ela ligados, uma vez que não tem qualquer controlo sobre os mesmos. Os utilizadores são os únicos responsáveis pelo acesso e uso de quaisquer sites administrados por terceiros."
      },
      "sec9": {
        "title": "9. Disposições finais",
        "p1": "Caso tenha alguma dúvida, ou necessite de informação adicional sobre os presentes Termos e Condições, poderá contactar-nos:",
        "p2": "Kropie, Régia Douro Park, 5000-033 Vila Real, Portugal, Email: info{'@'}kropie.com",
        "p3": "Quaisquer litígios emergentes da utilização desta plataforma serão resolvidos de acordo com a legislação em vigor em Portugal, salvo disposição expressa em contrário da lei."
      }
    },
    "policy": {
      "title": "Política de Privacidade",
      "last_update": "Última atualização: 21 de junho 2022",
      "p1": "Os dados pessoais recolhidos nesta plataforma são processados de acordo com o Regulamento Geral de Proteção de Dados (RGPD) e demais legislação aplicável à proteção de dados pessoais, como a Lei n.º 58/2019 de 8 de agosto.",
      "p2": "De acordo com os artigos 13.º e 14.º do RGPD, a presente Política de Privacidade descreve como processamos os dados pessoais que recebemos quando navega na nossa plataforma, quando se regista para receber algum tipo de informação da nossa parte ou quando nos contacta através do formulário de contacto, por e-mail, telefone ou correio, bem como quais os seus direitos em matéria de dados pessoais e como pode exercê-los.",
      "sec1": {
        "title": "1. Informações gerais",
        "subsec11": {
          "title": "1.1. Responsável pelo tratamento dos dados",
          "p1": "De acordo com o Artigo 4.º, n.º 7, do RGPD, o responsável pelo tratamento dos seus dados pessoais é:",
          "p2": "Kropie, Régia Douro Park, 5000-033 Vila Real, Portugal, Email: info{'@'}kropie.com"
        },
        "subsec12": {
          "title": "1.2. Dados pessoais",
          "p1": "Por \"dados pessoais\" entende-se qualquer informação relativa a uma pessoa física identificada ou identificável. Uma pessoa física identificável é uma pessoa singular que pode ser identificada, direta ou indiretamente, particularmente por referência a um identificador, como por exemplo um nome, um número de identificação, dados de localização, identificadores por via eletrónica, ou a um ou mais elementos específicos da identidade física, fisiológica, genética, mental, económica, cultural ou social dessa pessoa singular (Artigo 4.º, n.º 1, RGPD)."
        },
        "subsec13": {
          "title": "1.3. Base legal para o processamento",
          "p1": "Os seus dados pessoais são processados de acordo com as seguintes disposições do RGPD:",
          "l1": "Artigo 6.º, n.º 1, alínea a), nas situações em que o titular dos dados tenha dado o seu consentimento;",
          "l2": "Artigo 6.º, n.º 1, alínea b), se o processamento for necessário para a execução de um contrato ou para diligências pré-contratuais a pedido do titular dos dados;",
          "l3": "Artigo 6.º, n.º 1, alínea c), se o tratamento for necessário para o cumprimento de uma obrigação legal a que esteja sujeita a Estrutura de Missão Portugal Digital;",
          "l4": "Artigo 6.º, n.º 1, alínea d), se o tratamento for necessário para proteger os interesses vitais do titular dos dados ou de outra pessoa singular;",
          "l5": "Artigo 6.º, n.º 1, alínea e), se o tratamento for necessário ao exercício de funções de interesse público ou no exercício da autoridade pública do responsável pelo tratamento."
        }        
      },
      "sec2": {
        "title": "2. Dados processados quando visita este site",
        "subsec21": {
          "title": "2.1. Recolha de dados",
          "p1": "Sempre que um utilizador acede a esta plataforma, os seus dados de atividade são armazenados temporariamente sendo que a ferramenta de rastreio utilizada para analisar o uso deste site está configurada para garantir que nenhum dado pessoal seja recolhido.",
          "p2": "Nos termos do art.º 35º da CRP e da Lei n.º 67/98, de 26 de outubro, a informação recolhida sobre os utilizadores não autenticados através da plataforma web da Kropie não é de caráter pessoal, pelo que se assegura a confidencialidade da identidade do utilizador.",
          "p3": "Existirá, contudo, lugar a identificação dos utilizadores, autenticados ou não, sempre que cometam atos ilegais dentro ou com recurso à plataforma, violem o respetivo código de conduta, ou quando tal for solicitado mediante decisão judicial.",
          "p4": "A Kropie compromete-se a tomar todas as medidas necessárias para evitar perdas, má utilização, ou alteração da informação recebida dos utilizadores.",
          "p5": "A plataforma web da Kropie, assim como as várias páginas que lhe possam estar associadas podem recolher e gerir dados do utilizador, autenticados ou não, sempre de acordo com a seguinte política de privacidade:",
          "l1": "São recolhidos dados anónimos de cada visitante e/ou utilizador (nomeadamente o seu endereço IP) para monitorização do tráfico de dados e correção de deficiências ou erros de operação da plataforma;",
          "l2": "No caso de adesão à plataforma web da Kropie (através de registo), é-lhe solicitada informação pessoal básica (nome, nome de utilizador e endereço de e-mail) no sentido de serem usados como forma de autenticação;",
          "l3": "O seu e-mail pode ser utilizado para lhe enviar avisos, notificações e outras informações que são do seu interesse, sempre que indique essa intenção na sua página de perfil;",
          "l4": "De forma a melhorar a plataforma da Kropie, os utilizadores podem ser convidados a fornecer outras informações pessoais, como por exemplo, fotografias e/ou outros conteúdos, sendo o fornecimento destas informações opcional e estritamente voluntário, existindo sempre e a qualquer momento a possibilidade de eliminar esses mesmos conteúdos se assim o for desejado;",
          "l5": "Relativamente a qualquer tipo de conteúdo e/ou dados que o utilizador escolha submeter e/ou visualizar com recurso à plataforma, a Kropie toma a privacidade e a confidencialidade de tais dados de forma muito séria, sendo o armazenamento desses dados feito de forma segura em servidores próprios e incorporam medidas normalizadas de proteção contra o acesso não autorizado desses dados, incluindo, mas não limitado a, informação pessoal do utilizador;",
          "l6": "A Kropie não partilha quaisquer informação pessoal e dados dos seus utilizadores registados com nenhuma outra entidade, parceira ou não, sem a devida autorização do utilizador expressa por escrito para o efeito, excetuando os seguintes casos:",
          "l61": "Que seja solicitado em algum pedido por parte do respetivo utilizador;",
          "l62": "Que os gestores das plataformas da Kropie acreditem reforçar os Termos e Condições do serviço prestado, ou requerido legalmente;",
          "l63": "Que os gestores das plataformas da Kropie acreditem ser necessário para detetar, prevenir ou combater qualquer tipo de fraude, debilidades de segurança informática e/ou qualquer outro problema técnico;",
          "l64": "Que seja necessário para a proteção da propriedade intelectual, industrial, direitos legais ou de outra ordem de todos e quaisquer conteúdos facultados ao utilizador."
        },
        "subsec22": {
          "title": "2.2. Cookies",
          "p1": "Podem ser utilizados cookies para armazenar determinadas informações e assim melhorar a sua experiência de utilização da plataforma.",
          "p2": "Ainda assim, caso não deseje que a sua atividade no site seja registada e analisada, pode ativar a opção “Não rastrear” do seu navegador. Ao acionar esta opção, o seu navegador informa a nossa plataforma de que não deseja que a sua atividade seja rastreada.",
          "p3": "Os utilizadores podem, contudo, e a qualquer momento bloquear ou apagar as cookies não comprometendo ainda assim a utilização da plataforma;"
        }
      },
      "sec3": {
        "title": "3. Dados pessoais tratados quando nos contacta",
        "p1": "Quando nos contacta, os seus dados e informações por si facultados são processados para entrarmos em contacto consigo e respondermos ao seu pedido de informações. Esses dados serão processados e armazenados, de acordo com o RGPD, pelo período de três anos. Em momento algum os seus dados de contacto divulgado a terceiros, exceto se tal for solicitado por sentença judicial.",
        "p2": "Se o seu pedido não couber no âmbito das nossas competências e atribuições, poderá ser encaminhado às entidades competentes sem o seu consentimento prévio, de acordo com as disposições do RGPD, desde que seja legítimo presumir que o encaminhamento da questão salvaguarda os seus interesses e que não será incluído nenhum dado pessoal sensível (Artigo 6.º, n.º 1, alínea e), do RGPD)."
      },
      "sec4": {
        "title": "4. Os seus direitos",
        "p1": "Caso considere que o tratamento dos seus dados pessoais constitui uma violação do RGPD, tem o direito de reclamar a uma autoridade de controlo (artigo 77.º, RGPD).",
        "p2": "A autoridade de controlo nacional para efeitos de RGPD é:",
        "p3": "Comissão Nacional de Proteção de Dados, Av. D. Carlos I, 134, 1.º, 1200-651 Lisboa, Portugal, Telefone: +351 213 928 400, Email: geral{'@'}cnpd.pt"
      }
    }
  },
  "search": {
    "title": "Procurar",
    "popular_searches": "Pesquisas Populares",
    "my_devices": "Os meus dispositivos",
    "public_devices": "Dispositivos públicos",
    "no_results": "Sem resultados para :query",
    "devices": "Dispositivos",
    "observations": "Observações",
    "maps": "Mapas",
    "notebooks": "Cadernos de campo"
  }
}
